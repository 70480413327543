<template>
  <div
    class="h-screen flex flex-col"
    v-if="project && materials && operations && printers"
  >
    <Navigation />
    <div class="flex items-center px-4 py-3 border-b border-gray-400 bg-white">
      <ElButton
        @click="saveProject"
        icon="el-icon-check"
        type="primary"
        :disabled="saving"
        >Salveaza</ElButton
      >
      <ElButton @click="openPriceDialog" icon="el-icon-money">Pret</ElButton>
      <ElButton @click="openStockStatusDialog" icon="el-icon-document"
        >Stocuri</ElButton
      >
      <ElButton @click="openTemplatesDialog" icon="el-icon-files"
        >Sabloane</ElButton
      >
      <ElButton @click="openActivitiesDialog">Activitati</ElButton>
      <div class="flex-1" />
      <ElButton @click="printInfo($route.params.id)">
        Tiparire Fisa
      </ElButton>
      <ElButton
        v-if="
          projectData.stage !== 'active' && projectData.stage !== 'completed'
        "
        class="mr-5"
        @click="setStageActive"
        icon="el-icon-s-promotion"
        type="danger"
        :disabled="saving"
        >trimite in lucru</ElButton
      >
      <ElSwitch v-model="advanced" active-text="Avansat" />
    </div>
    <div v-if="!advanced" class="flex-1 flex min-h-0 bg-white overflow-x-auto">
      <ProjectGeneralPanel
        @updatePrice="updatePrice"
        :project="project"
        :projectId="id"
      />
      <ProjectComponentPanel
        v-for="(componentData, componentId) in projectData.components"
        :key="componentId"
        :componentData="componentData"
        :evaluate="evaluate"
        :printers="printers"
        :printersById="printersById"
        :largeSheets="largeSheets"
        :materialCategories="materialCategories"
        :hideName="true"
        @regenerate="regenerateComponent(componentId)"
      />
    </div>
    <div v-else class="flex-1 flex min-h-0">
      <ProjectTaskPanel
        v-if="selectedElement && selectedElement.type === 'task'"
        :taskData="selectedElement"
        :evaluate="evaluate"
        :operationsById="operationsById"
        :materialsById="materialsById"
        :context="context"
      />
      <ProjectComponentPanel
        v-else-if="selectedElement && selectedElement.type === 'component'"
        :key="selectedElement.id"
        :componentData="selectedElement"
        :evaluate="evaluate"
        :printers="printers"
        :printersById="printersById"
        :largeSheets="largeSheets"
        :materialCategories="materialCategories"
        :hideName="false"
        @regenerate="regenerateComponent(selectedElement.id)"
      />
      <ProjectGeneralPanel v-else :project="project" :projectId="id" />
      <div class="flex-1 overflow-hidden relative">
        <div
          class="w-full h-full"
          ref="cy"
          v-mount="mountGraph"
          v-unmount="unmountGraph"
        />
        <div class="absolute top-0 left-0 m-5 z-10">
          <ElTooltip content="Aranjeaza">
            <ElButton
              @click="refresh"
              circle
              class="text-lg"
              icon="el-icon-refresh"
            />
          </ElTooltip>
          <ElTooltip content="Sarcina">
            <ElButton
              @click="addTask"
              circle
              class="text-lg"
              icon="el-icon-plus"
            />
          </ElTooltip>
          <ElTooltip content="Componenta">
            <ElButton
              @click="addComponent"
              circle
              class="text-lg"
              icon="el-icon-folder-add"
            />
          </ElTooltip>
          <!-- {{ projectData }} -->
        </div>
        <div class="absolute bottom-0 left-0 m-5 z-10">
          <ElTooltip content="Sterge" v-if="selectedElement">
            <ElButton
              @click="removeSelectedElement"
              circle
              class="text-lg"
              icon="el-icon-delete"
            />
          </ElTooltip>
          <ElTooltip
            content="Conecteaza"
            v-if="
              selectedElement &&
                (selectedElement.type === 'task' ||
                  selectedElement.type === 'component')
            "
          >
            <ElButton
              @click="connectSelectedElement"
              circle
              class="text-lg"
              icon="el-icon-link"
              :type="connectMode ? 'success' : null"
            />
          </ElTooltip>
        </div>
      </div>
    </div>
    <!-- dialogs -->
    <ProjectTemplatesDialog
      ref="templatesDialog"
      v-if="project"
      :project="project"
      @select="onTemplateSelect"
    />
    <ProjectPriceDialog
      ref="priceDialog"
      @save="saveProject"
      v-if="project && printers && operations && materials"
      :project="project"
      :context="context"
      :database="database"
    />
    <ProjectStockStatusDialog
      ref="stockStatusDialog"
      v-if="project"
      :project="project"
      :context="context"
      :materialsById="materialsById"
    />
    <ProjectActivitiesDialog
      ref="activitiesDialog"
      v-if="project"
      :projectId="id"
    />
    <el-dialog title="Nume lucrare" :visible.sync="nameDialog">
      <el-input placeholder="nume lucrare" v-model="project.name"></el-input>
      <div class="flex row mt-2">
        <div class="flex-1 flex row ">
          <el-checkbox
            label="face parte din grup"
            v-model="project.hasgroup"
          ></el-checkbox>
          <el-input
            class="mx-5"
            style="width:150px"
            v-if="project.hasgroup"
            placeholder="id grup"
            v-model.number="project.groupid"
          ></el-input>
        </div>
        <el-button type="primary" @click="saveAndClose">salveaza</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  taskInstanceBase,
  getComponent,
  evaluateInContext,
  baseComponentRequest,
  normalizeComponentRequest,
} from "@/calculator"
import cuid from "cuid"
import { keyBy } from "lodash"
import cytoscape from "cytoscape"
import dagre from "cytoscape-dagre"
import { graphStyle, getProjectGraphElements } from "@/utils/graph"
import ProjectGeneralPanel from "@/components/projects/ProjectGeneralPanel"
import ProjectTaskPanel from "@/components/projects/ProjectTaskPanel"
import ProjectComponentPanel from "@/components/projects/ProjectComponentPanel"
import ProjectTemplatesDialog from "@/components/projects/ProjectTemplatesDialog"
import ProjectPriceDialog from "@/components/projects/ProjectPriceDialog"
import ProjectStockStatusDialog from "@/components/projects/ProjectStockStatusDialog"
import ProjectActivitiesDialog from "@/components/projects/ProjectActivitiesDialog"

import { sumBy } from "lodash"
import { getTaskPrice } from "@/calculator"

cytoscape.use(dagre)

export default {
  props: ["id"],
  components: {
    ProjectGeneralPanel,
    ProjectTaskPanel,
    ProjectComponentPanel,
    ProjectTemplatesDialog,
    ProjectPriceDialog,
    ProjectStockStatusDialog,
    ProjectActivitiesDialog,
  },
  data() {
    return {
      nameDialog: false,
      advanced: false,
      selectedElement: null,
      connectMode: false,
      printers: null,
      operations: null,
      materials: null,
      materialCategories: null,
      project: null,
      projectData: null,
      saving: false,
    }
  },
  computed: {
    printersById() {
      return keyBy(this.printers, "id")
    },
    operationsById() {
      return keyBy(this.operations, "id")
    },
    materialsById() {
      return keyBy(this.materials, "id")
    },
    largeSheets() {
      if (this.materials === null) return null
      return this.materials.filter(
        ({ data }) => data.weight && data.width && data.height
      )
    },
    context() {
      return this.project.variables
    },
    database() {
      return {
        printersById: this.printersById,
        operationsById: this.operationsById,
        materialsById: this.materialsById,
      }
    },
  },
  async created() {
    this.initGraph()
    await this.fetchProject()
    this.$bind("printers", this.$firestore().collection("printers"))
    this.$bind("operations", this.$firestore().collection("operations"))
    this.$bind("materials", this.$firestore().collection("materials"))
    this.$bind(
      "materialCategories",
      this.$firestore().collection("materialCategories")
    )

    if (this.project.name === "") {
      console.log("yes")
      this.nameDialog = true
      // this.$prompt("Nume lucrare ","Nume", {
      //   confirmButtonText: "OK",
      //     cancelButtonText: "Cancel",
      //   })
      //   .then(({ value }) => {
      //       this.project.name = value
      //       this.saveProject()
      //   })
      //    .catch(() => {
      //       // this.completeTask(false);
      //       this.$message({
      //         type: "info",
      //         message: "Input canceled"
      //       });
      //     });
    }
  },
  watch: {
    projectData: {
      deep: true,
      handler() {
        const elements = getProjectGraphElements(this.projectData)
        this.cy.json({ elements })
      },
    },
  },
  methods: {
    saveAndClose() {
      this.saveProject()
      this.nameDialog = false
    },
    windowOpen(...args) {
      const win = window.open(...args)
      if (!win) this.$alert("Browser-ul a blocat deschiderea ferestrei.")
    },
    printInfo(id) {
      const path = `/print/project-info/${id}`
      this.windowOpen(this.$router.resolve(path).href)
    },
    async updatePrice() {
      const { margin, tasks } = this.project
      const basePrice = sumBy(Object.values(tasks), (task) =>
        getTaskPrice(task, this.context, this.database)
      )
      this.project.price = basePrice * (1 + margin / 100)
      this.projectData.fixPrice = !this.projectData.fixPrice
          ? 0
          : this.projectData.fixPrice
      // this.saveProject()
    },
    initGraph() {
      this.cy = cytoscape({
        elements: [],
        minZoom: 0.5,
        maxZoom: 1,
        boxSelectionEnabled: false,
        selectionType: "single",
        style: graphStyle,
      })

      this.cy.on("select", this.onSelectElement)
      this.cy.on("unselect", this.onUnselectElement)
    },
    mountGraph(el) {
      this.cy.mount(el)
      this.refresh()
    },
    unmountGraph() {
      this.cy.unmount()
    },
    async fetchProject() {
      const projectSnapshot = await this.$fetch(
        this.$firestore().doc(`projects/${this.id}`)
      )
      this.project = projectSnapshot.data
      this.projectData = projectSnapshot.data

      // if (Object.values(this.projectData.tasks).length === 0) {
      //   this.$nextTick(this.openTemplatesDialog);
      // }
    },
    async setStageActive() {
      this.projectData.stage = "active"
      await this.saveProject()
    },
    async saveProject() {
      try {
        this.saving = true
        //bug on el-input with new fields -nu ma lasa sa salvez daca e null
        this.projectData.fixPrice = !this.projectData.fixPrice
          ? 0
          : this.projectData.fixPrice
        //===

        // this.syncGraphToData();
        await this.$firestore()
          .doc(`projects/${this.id}`)
          .update({
            ...this.projectData,
            updatedAt: this.$firestore.FieldValue.serverTimestamp(),
          })
        this.$message.success("Modificarea a fost efectuata.")
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.")
      } finally {
        this.saving = false
      }
    },
    refresh() {
      this.createHiddenEdges()
      this.runLayout()
    },
    runLayout() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.cy
            .layout({
              name: "dagre",
              nodeDimensionsIncludeLabels: true,
            })
            .run()
        })
      })
    },
    selectElement(id) {
      if (this.selectedElement) {
        this.cy.getElementById(this.selectedElement.id).unselect()
      }
      this.$nextTick(() => this.cy.getElementById(id).select())
    },
    onSelectElement(event) {
      const id = event.target.id()
      const type = event.target.data("type")

      if (type === "task") {
        this.selectedElement = this.projectData.tasks[id]
      } else if (type === "component") {
        this.selectedElement = this.projectData.components[id]
      } else {
        this.selectedElement = this.projectData.edges[id]
      }
    },
    onUnselectElement() {
      this.selectedElement = null
    },
    removeSelectedElement() {
      this.removeElement(this.selectedElement)
      this.selectedElement = null
    },
    removeElement(element) {
      const { id, type } = element
      if (type === "task") {
        this.$delete(this.projectData.tasks, id)
        this.removeConnectedEdges(id)
      } else if (type === "component") {
        this.$delete(this.projectData.components, id)
        this.removeConnectedEdges(id)
        this.removeChildrenNodes(id)
      } else {
        this.$delete(this.projectData.edges, id)
      }
    },
    removeConnectedEdges(id) {
      this.cy
        .getElementById(id)
        .connectedEdges()
        .forEach((edge) => {
          this.$delete(this.projectData.edges, edge.id())
        })
    },
    removeChildrenNodes(id) {
      this.cy
        .getElementById(id)
        .children()
        .forEach((node) => {
          this.removeConnectedEdges(node.id())
          this.$delete(this.projectData.tasks, node.id())
        })
    },
    connectSelectedElement() {
      this.cy.autounselectify(true)
      this.connectMode = true

      this.cy.once("tap", (event) => {
        this.connectMode = false
        if (event.target === this.cy) return
        if (!event.target.isNode()) return
        if (event.target.isChild()) return

        const source = this.selectedElement.id
        const target = event.target.id()

        if (source === target) return

        const id = cuid()
        this.$set(this.projectData.edges, id, {
          id,
          source,
          target,
        })
        this.refresh()
        this.selectElement(target)

        this.cy.autounselectify(false)
      })
    },
    connectManyToMany(sources, targets, extra) {
      sources.forEach((source) => {
        targets.forEach((target) => {
          const id = cuid()
          this.$set(this.projectData.edges, id, {
            id,
            source: source.id(),
            target: target.id(),
            ...extra,
          })
        })
      })
    },
    createHiddenEdges() {
      this.cy.edges("edge[hidden]").forEach((edge) => {
        this.$delete(this.projectData.edges, edge.id())
      })
      this.$nextTick(() => {
        this.cy.edges().forEach((edge) => {
          const source = edge.source()
          const target = edge.target()
          const sourceIsTask = source.data("type") === "task"
          const targetIsTask = target.data("type") === "task"
          // one node must be component
          if (sourceIsTask && targetIsTask) return
          this.connectManyToMany(
            sourceIsTask ? source : source.children().leaves(),
            targetIsTask ? target : target.children().roots(),
            { hidden: true }
          )
        })
      })
    },
    addTask() {
      const id = cuid()
      this.$set(this.projectData.tasks, id, {
        id,
        type: "task",
        name: "???",
        operations: [],
        materials: [],
        ...taskInstanceBase,
      })
      this.selectElement(id)
      this.refresh()
    },
    addComponent() {
      const request = normalizeComponentRequest(
        {
          ...baseComponentRequest,
          pages: 2,
          frontColors: 4,
          backColors: 4,
          finalSheetLayouts: ["1x1"],
          sheetCategoryId: "zeIfm7sFicTJLtt1pZBD",
          sheetWeight: 130,
        },
        this.printers.filter(printer => printer.data.enabled),
        this.largeSheets
      )
      const id = cuid()
      //aici se face mapping la printing
      this.$set(this.projectData.components, id, {
        id,
        type: "component",
        name: "???",
        request,
      })

      this.selectElement(id)
      this.regenerateComponent(id)
    },
    regenerateComponent(componentId) {
      //hotfix raul - softul se blocheaza cand nu ai dimensiune
      if (this.context.height !== "" && this.context.width !== "") {
        const componentElement = this.projectData.components[componentId]
        componentElement.request = {
          ...baseComponentRequest,
          ...componentElement.request,
        }

        const { request } = componentElement
        /// aici se face maparea din true/false in array
        const { impositions, tasks } = getComponent(
          request,
          this.context,
          this.database
        )
        const nodes = tasks.map((task) => ({
          id: cuid(),
          type: "task",
          parent: componentId,
          ...task,
          ...taskInstanceBase,
        }))

        const edges = []
        for (let i = 0; i < nodes.length - 1; i++) {
          edges.push({
            id: cuid(),
            source: nodes[i].id,
            target: nodes[i + 1].id,
          })
        }

        this.cy
          .getElementById(componentId)
          .children()
          .forEach((task) => this.removeElement(task.data()))

        this.$set(componentElement, "impositions", impositions)

        nodes.forEach((node) => {
          this.$set(this.projectData.tasks, node.id, node)
        })
        edges.forEach((edge) => {
          this.$set(this.projectData.edges, edge.id, edge)
        })

        this.refresh()

        if (impositions.length === 0) {
          alert("Nu am gasit nici o solutie")
        }
      } else {
        alert("Seteaza formatul lucrarii !!!")
      }
    },
    evaluate(expr) {
      try {
        return evaluateInContext(expr, this.context)
      } catch (error) {
        return "???"
      }
    },
    onTemplateSelect(template) {
      this.project.edges = template.edges
      this.project.tasks = template.tasks
      this.project.components = template.components
      const oldQ = this.project.variables.quantity
      this.project.variables = template.variables
      this.project.variables.quantity = oldQ
    },
    openTemplatesDialog() {
      this.$refs.templatesDialog.open()
    },
    openPriceDialog() {
      this.$refs.priceDialog.open()
    },
    openStockStatusDialog() {
      this.$refs.stockStatusDialog.open()
    },
    openActivitiesDialog() {
      this.$refs.activitiesDialog.open()
    },
  },
}
</script>
